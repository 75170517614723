import { Auth, Stage } from "@efishery/sdk-core";
import { jwtDecode } from "jwt-decode";
import qs from "query-string";
import { ENV } from "src/configs/env";
import { SERVICE } from "src/configs/service";

import { AuthClientOptions, ExchangeToken, User, Verify } from "./global";
import { getUserPermissions } from "./utils";

const auth = new Auth(
  ENV.APP_CLIENT_ID,
  Stage[ENV.APP_ENV as keyof typeof Stage]
);

const AuthClient = ({
  redirectUri = window.location.origin,
}: AuthClientOptions) => {
  const loginWithRedirect = async ({ returnTo }: { returnTo?: string }) => {
    const challengeCode = await auth.generateChallengeCode();
    const url = `${SERVICE.AUTH}/?code_challenge=${challengeCode}&client_id=${ENV.APP_CLIENT_ID}&redirect_url=${redirectUri}${returnTo}&failed_redirect_url=${redirectUri}${returnTo}`;
    window.location["assign"](url);
  };

  const handleRedirectCallback = async (
    url: string = window.location.href
  ): Promise<any> => {
    const queryStringFragments = url.split("?").slice(1);

    if (queryStringFragments.length === 0) {
      throw new Error("There are no query params available for parsing.");
    }

    const { code, to, grant_type } = qs.parse(queryStringFragments.join(""));

    await auth.persistExchange(code as string, grant_type as string);

    return {
      returnTo: to,
    };
  };

  const handleLogout = async (returnTo: string): Promise<void> => {
    Promise.resolve();

    await auth.logout();

    window.location.assign(returnTo);
  };

  const getUser = async (): Promise<{ data: ExchangeToken }> => {
    const accessToken = auth.getAccessToken() as string;
    const profile = await auth.getProfile(accessToken);
    const refreshToken = auth.getRefreshToken() as string;

    return {
      data: { profiles: profile.data as User, accessToken, refreshToken },
    };
  };

  const checkAppPermisson = async (user: User) => {
    const { hasGlobalAccess } = getUserPermissions(user);
    if (!hasGlobalAccess) {
      await auth.logout();
      throw new Error(
        "You do not have a right perrmission to access this page. If you think this was a mistake, Please contact Admin"
      );
    }

    return Promise.resolve();
  };

  const buildUserFromToken = async (
    exchangeToken: ExchangeToken
  ): Promise<Sessions> => {
    const { profiles, accessToken, refreshToken } = exchangeToken;
    const { picture }: User = await jwtDecode(accessToken);
    return {
      user: {
        ...(profiles as User),
        avatar: picture,
      },
      accessToken,
      refreshToken,
    };
  };

  interface Sessions extends Verify {
    user: User;
  }

  const checkSession = async (): Promise<Sessions> => {
    const accessToken = auth.getAccessToken();

    if (!accessToken) {
      throw new Error("");
    }

    const { data } = await getUser();
    const user = await buildUserFromToken(data);
    await checkAppPermisson(user.user);

    return user;
  };

  return {
    loginWithRedirect,
    handleRedirectCallback,
    handleLogout,
    getUser,
    checkSession,
  };
};

export default AuthClient;
