import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "src/features/Login";
import { useAuth } from "src/libs/authv2";

import router from "./router";

const AppRouter: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <BrowserRouter>
      <Routes>
        {/* Authenticated Route */}
        {router.map((route, idx) => {
          const {
            routerProps: { element, ...restProps },
          } = route;
          return (
            <Route
              {...restProps}
              key={`${route.name}-${idx}`}
              element={
                isAuthenticated ? (
                  element
                ) : (
                  <Navigate to={`/login${window.location.search}`} replace />
                )
              }
            />
          );
        })}
        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate to="/demo/promoku" replace />
            ) : (
              <LoginPage />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
