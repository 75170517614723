import { IRouteProps } from "src/routes/type";

import ReferralPage from "./Referral";

const router: IRouteProps[] = [
  {
    name: "Referral Page",
    routerProps: {
      path: "/demo/referral",
      element: <ReferralPage />,
    },
  },
];

export default router;
