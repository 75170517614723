import { createContext } from "react";

import { AuthState, initialAuthState } from "./auth-state";
import { User } from "./global";

export interface AuthContextInterface<TUser extends User = User>
  extends AuthState<TUser> {
  loginWithRedirect: (redirectUri: string) => void;
  logout: ({ returnTo }: { returnTo?: string }) => void;
}

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <AuthProvider>.");
};

const initialContext = {
  ...initialAuthState,
  loginWithRedirect: stub,
  logout: stub,
};

const AuthContext = createContext<AuthContextInterface>(initialContext);

export default AuthContext;
