import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import AuthContext from "./auth-context";
import { initialAuthState } from "./auth-state";
import AuthClient from "./AuthClient";
import { reducer } from "./reducer";
import { hasAuthParams, loginError } from "./utils";

const defaultOnRedirectCallback = (returnTo?: string): void => {
  window.history.replaceState(
    {},
    document.title,
    returnTo || window.location.pathname
  );
};

export interface AuthProviderOptions {
  domain: string;
  children?: React.ReactNode;
  onRedirectCallback?: (returnTo?: string) => void;
  redirectUri?: string;
  withSingleSignOn?: boolean;
}

const AuthProvider = (opts: AuthProviderOptions): React.JSX.Element => {
  const {
    children,
    onRedirectCallback = defaultOnRedirectCallback,
    ...options
  } = opts;
  const [auth] = useState(() => AuthClient(options));
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const didInitialise = useRef(false);

  useEffect(() => {
    if (didInitialise.current) {
      return;
    }
    didInitialise.current = true;
    (async (): Promise<void> => {
      try {
        if (hasAuthParams()) {
          const { returnTo } = await auth.handleRedirectCallback();
          onRedirectCallback(returnTo);
        }

        const sessions = await auth.checkSession();
        const { user } = sessions;

        dispatch({ type: "INITIALISED", user });
      } catch (error) {
        dispatch({
          type: "ERROR",
          error: loginError(error as Error),
        });
      }
    })();
  }, [auth, onRedirectCallback]);

  const loginWithRedirect = useCallback(
    (returnTo?: string): void => {
      auth.loginWithRedirect({ returnTo });
    },
    [auth]
  );

  const logout = useCallback(
    ({ returnTo = "/" }): Promise<void> | void => {
      const promise = auth.handleLogout(returnTo);
      dispatch({ type: "LOGOUT" });

      return promise;
    },
    [auth]
  );

  const contextValue = useMemo(() => {
    return {
      ...state,
      loginWithRedirect,
      logout,
    };
  }, [state, logout, loginWithRedirect]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
export default AuthProvider;
