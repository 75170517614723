import { Button, Center, Heading, Text, VStack } from "@efishery/onefish";
import { FaGoogle } from "react-icons/fa";
import { ENV } from "src/configs/env";
import { useAuth } from "src/libs/authv2";

export default function LoginPage() {
  const { loginWithRedirect, isLoading } = useAuth();
  return (
    <Center
      h="100vh"
      background="radial-gradient(50% 50% at 50% 50%, var(--chakra-colors-brand-500) 0%, var(--chakra-colors-brand-700) 100%)"
    >
      <VStack gap="2rem">
        <Heading fontSize="32px" color="white" textAlign="center">
          Welcome to eFisheryKu Growth Remote
        </Heading>
        <VStack>
          <Button
            isLoading={isLoading}
            leftIcon={<FaGoogle />}
            fontWeight="500"
            onClick={() => {
              loginWithRedirect(`/demo/promoku${window.location.search}`);
            }}
          >
            Sign with Google
          </Button>
          <Text
            fontSize="14px"
            color="white"
            fontWeight="500"
            data-testid="txt_loginpage-version"
          >
            {ENV.APP_VERSION_TAG}
          </Text>
        </VStack>
      </VStack>
    </Center>
  );
}
