import { createRoot } from "react-dom/client";

import App from "./App.tsx";
import { sentryInitialization } from "./utils/sentry.ts";

// init Sentry
sentryInitialization();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
