import { IRouteProps } from "src/routes/type";

import LoyaltyPage from "./List";

const router: IRouteProps[] = [
  {
    name: "Loyalty List Page",
    routerProps: {
      path: "/demo/loyalty",
      element: <LoyaltyPage />,
    },
  },
];

export default router;