import { IRouteProps } from "src/routes/type";

import PromoKuDetailPage from "./Detail";
import PromoKuListPage from "./List";

const router: IRouteProps[] = [
  {
    name: "PromoKu Page",
    routerProps: {
      path: "/demo/promoku",
      element: <PromoKuListPage flagPromoKuRevamp={false} />,
    },
  },
  {
    name: "PromoKu V2 Page",
    routerProps: {
      path: "/demo/promoku-v2",
      element: <PromoKuListPage flagPromoKuRevamp />,
    },
  },
  {
    name: "PromoKu Detail Page",
    routerProps: {
      path: "/demo/promoku/:promoId",
      element: <PromoKuDetailPage />,
    },
  },
];

export default router;
