import { AxiosError, isAxiosError } from "axios";

import { OAuthError } from "./errors";
import { IUserIdentity, OptionalUserProfile, User } from "./global";

const CODE_RE = /[?&]code=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

export const hasAuthParams = (searchParams = window.location.search): boolean => {
  return CODE_RE.test(searchParams) || ERROR_RE.test(searchParams);
}
  

export interface AuthenticationResult {
  code: string;
  to?: string;
  error?: string;
  error_description?: string;
}

export function autoImplement<T>(): new () => T {
  return class {} as any;
}

const normalizeErrorFn =
  (fallbackMessage: string) =>
  (
    error: Error | { error: string; error_description?: string } | ProgressEvent
  ): Error => {
    if ("error" in error) {
      return new OAuthError(error.error, error.error_description);
    }
    if (isAxiosError(error)) {
      const err = error as AxiosError<{ message: string }>;
      return new Error(err.response?.data.message);
    }
    if (error instanceof Error) {
      return error;
    }
    return new Error(fallbackMessage);
  };

export const loginError = normalizeErrorFn("Unauthorized access!");

export const tokenError = normalizeErrorFn("Get access token failed");

export const asyncLocalStorage = {
  setItem: async function (key: string, value: string) {
    await Promise.resolve();
    localStorage.setItem(key, value);
  },
  getItem: async function (key: string) {
    await Promise.resolve();
    return localStorage.getItem(key);
  },
  removeItem: async function (key: string) {
    await Promise.resolve();
    return localStorage.removeItem(key);
  },
  clear: async function () {
    await Promise.resolve();
    return localStorage.clear();
  },
};

export const checkExpired = (payload: any) => {
  const now = new Date().getTime() / 1000;
  if (payload?.exp > now) {
    return false;
  }
  return true;
};

export function generateUserIdentity(user: User) {
  const permissions = getUserPermissions(user);

  return {
    id: user.uuid,
    name: user.full_name,
    email: user.email,
    avatar: user.avatar,
    createdAt: user.created_at,
    lastModified: user.modified_at,
    createdBy: user.created_by,
    hasAccess: permissions.hasGlobalAccess,
    permissions: permissions.userPermissions,
  } as IUserIdentity;
}
export const getUserPermissions = (user: User) => {
  const { permissions } = user;

  const userPermissions = (
    permissions as OptionalUserProfile["permissions"]
  )?.reduce(
    (prev, perm) => {
      const appDomain = "efishery-marketing-portals";
      if (perm[0] === "g" && perm[1] === user.email && perm[3] === appDomain) {
        return (prev = { ...prev, global: [...prev.global, perm] });
      }

      if (perm[0] === "p" && perm[2] === appDomain) {
        return (prev = { ...prev, policies: [...prev.policies, perm] });
      }

      return prev;
    },
    { global: [], policies: [] }
  );

  return {
    hasGlobalAccess: Boolean(userPermissions.global.length > 0),
    userPermissions,
  };
};
