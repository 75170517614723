import { OneFishProvider } from "@efishery/onefish";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { SERVICE } from "./configs/service";
import { AuthProvider } from "./libs/authv2";
import AppRouter from "./routes";

dayjs.extend(utc);
dayjs.extend(timezone);
function App() {
  return (
    <OneFishProvider>
      <AuthProvider domain={SERVICE.AUTH} redirectUri={window.location.origin}>
        <AppRouter />
      </AuthProvider>
    </OneFishProvider>
  );
}

export default App;
