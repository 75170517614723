import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ENV } from "src/configs/env";

export function sentryInitialization() {
  Sentry.init({
    dsn: ENV.APP_SENTRY_DSN,
    release: ENV.APP_VERSION_TAG,
    environment: ENV.APP_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    sampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

interface SetSentryUserProps {
  name: string;
  email: string;
}

export function setSentryUser(userProfile: SetSentryUserProps) {
  Sentry.setUser(userProfile);
}
